@mixin form-styles {
  label.required:after {
    content: '*';
    color: red;
  }
}

.required:after {
  content: '\00a0*';
  color: red;
}

.ng-invalid.ng-dirty {
  border-color: red;
}

.validation-error {
  @apply text-xs text-red-error;

  padding-top: 5px;
  margin-left: 10px;
  margin-top: 2px;
}

.text-red-error {
  color: red !important;
}

.formInput {
  @apply w-full input input-bordered;

  &.error {
    @apply input-error;
  }

  &.success {
    @apply input-success;
  }
}

.help {
  @apply text-xs my-1 ml-1;
}

::placeholder {
  color: #9ba3a0;
  opacity: 1;
}

@mixin formbase {
  @apply border-[1px] border-[#DEDAD8] border-solid h-[52px] text-[17px] w-full;
  &:focus {
    @apply outline-none border-gray-400;
  }
}

.input {
  @include formbase();
  @apply w-full font-[450];
}

.input + label {
  display: block;
  transform: translateY(-33px) translateX(20px);
  transition: 200ms ease-in-out;
  width: auto;
}

.input:focus {
  + label {
    transform: translateY(-75px);
  }
}

.input:disabled {
  @apply text-black;
}

.select:disabled {
  @apply text-black;
}

.input:not(:placeholder-shown) {
  + label {
    transform: translateY(-75px);
  }
}

.form-control {
  position: relative;
}

.placeholder {
  font-size: 17px;
  color: #9ba3a0;
}

.form-control span.placeholder {
  position: absolute;
  display: flex;
  align-items: center;
  top: 15px;
  left: 17px;
  transition: all 0.2s;
  transform-origin: 0% 0%;
  background: none;
  pointer-events: none;
}

.form-control-clearable {
  position: relative;
  .clear {
    position: absolute;
    padding: 6px 10px;
    right: 30px;
    top: 10px;
    cursor: pointer;
  }
}

.form-control input:valid:not(:placeholder-shown) + span.placeholder,
.form-control input:focus:not(:placeholder-shown) + span.placeholder,
.form-control input:disabled:not(:placeholder-shown) + span.placeholder,
.form-control select:valid:not(:placeholder-shown) + span.placeholder,
.form-control select:disabled:not(:placeholder-shown) + span.placeholder,
.form-control textarea:valid:not(:placeholder-shown) + span.placeholder,
.form-control textarea:disabled:not(:placeholder-shown) + span.placeholder,
.form-control textarea:focus:not(:placeholder-shown) + span.placeholder {
  transform: translateY(-44px) translateX(-17px);
  font-size: 13px;
  color: #687670;
}

.textarea {
  @include formbase();
  @apply h-[150px];
}

.select {
  @include formbase();
  @apply text-[#9BA3A0] font-[450];
  &.ng-valid {
    @apply text-black;
  }
  &:focus {
    @apply outline-none border-gray-400;
  }
  > option {
    @apply w-full text-black bg-white;
  }
}
