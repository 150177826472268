@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@angular/cdk/overlay-prebuilt.css';

/* You can add global styles to this file, and also import other style files */
// @import "flag-icons/css/flag-icons.min.css";
@import "./app/form.styles.scss";

html,body{
  @apply bg-[#F3F3F3] min-h-screen overflow-hidden;
  font-size: 15px;
}

.cdk-dialog-container{
  display: flex!important;
  background: #fff;
  border-radius: 8px;
  padding: 5px;
  >*{
    flex:1;
  }
  margin-top: 100px;
}

@import '../src/app/theme/theme.scss';

.bg-theme {
  background-color: var(--themeColour);
}

.theme-colour {
  color: var(--themeColour);
}

.btn-primary{
  @apply bg-theme text-white text-base;
}

.page-icon{
  font-size: 36px;
  margin-right: 1rem;
  line-height: 46px;
  color: var(--themeColour);
}

.box{
  @apply bg-white p-[60px] rounded-md;
}
