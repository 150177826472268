// https://medium.com/atom-platform/angular-6-dynamic-themes-without-a-library-c21dfb2cb580

// default colors
:root {
    --themeColour: #1FC788; //#01ADEE
}
// pass variables into a sass map
$variables: (
    --themeColour: var(--themeColour)
);

@function var($variable) {
    @return map-get($variables, $variable);
}